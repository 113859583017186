import React from 'react';
import { Card, CardContent, Typography, Box, CardActionArea, Button } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import {
    Circle,
    CheckCircle,
    CheckCircleOutline
} from '@mui/icons-material';

const EcommercePlanCard = () => {
    const [hover, setHover] = React.useState(false);

    return (
        <Card
        sx={{
            backgroundColor: '#1f1f1f',
            color: '#fff',
            p: { xs: 2, sm: 3, lg: 5 },
            pb: { xs: 0, lg: 5 },
            borderRadius: '30px',
            border: `3px solid #333333`,
            transition: 'all 0.3s ease', // Smooth hover effect
            '&:hover': {
                backgroundColor: '#1f1f1f', // Change background color on hover
                border: `5px solid #F8AD13`,
            },
        }}
            onMouseEnter={() => setHover(true)} // Trigger when the mouse enters
            onMouseLeave={() => setHover(false)} // Trigger when the mouse leaves
        >
            <CardContent sx={{ p: 0 }}>
                <Typography
                    sx={{
                        textAlign: {xs: 'center', md: 'left'},
                        color: hover ? '#F8AD13' : 'white',
                        fontSize: {
                            xs: '2.5rem',
                            sm: '3rem',
                            md: '2.5rem',
                            lg: '2.5vw',
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 500,
                        mb: { xs: 2, sm: 1.5, lg: 3 },
                    }}
                >
                    E-commerce
                </Typography>
                <Typography
                    sx={{
                        textAlign: {xs: 'center', md: 'left'},
                        color: 'white',
                        fontSize: {
                            xs: '1.3rem',
                            sm: '1.5rem',
                            md: '1.2rem',
                            lg: '1.2vw',
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 200,
                        lineHeight: 0.1, // Reduce line spacing
                    }}
                >
                    As Low As
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'baseline', // Align items to their baselines
                        justifyContent: {xs: 'center', md: 'flex-start'},
                        gap: '4px', // Space between $125 and /month
                        mb: 1,
                    }}
                >
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: {
                                xs: '3.5rem',
                                sm: '3.5rem',
                                md: '3rem',
                                lg: '4vw',
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                            lineHeight: 1.4, // Reduce line spacing
                        }}
                    >
                        $250
                    </Typography>
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: {
                                xs: '1rem',
                                sm: '1.5rem',
                                md: '1.2rem',
                                lg: '1.2vw',
                            },
                            fontFamily: 'Montserrat',
                            fontWeight: 200,
                        }}
                    >
                        /month
                    </Typography>
                </Box>
                <Typography
                     sx={{
                        textAlign: {xs: 'center', md: 'left'},
                        color: 'white',
                        fontSize: {
                            xs: '1.3rem',
                            sm: '1.5rem',
                            md: '1.2rem',
                            lg: '1.25vw',
                        },
                        fontFamily: 'Montserrat',
                        fontWeight: 400,
                        lineHeight: 1.2, // Reduce line spacing
                        mb: { xs: 3, sm: 2, lg: 4 },
                    }}
                >
                    For businesses aiming to grow and reach new audiences.
                </Typography>
                <Box
                    sx={{
                        //ml: 3,
                    }}
                >
                    {
                        [
                            "Custom Coded & Templated",
                            "Dynamic & Responsive Webpages",
                            "E-commerce Shop Integration",
                            "Assisted Inventory Management",
                        ]
                            .map((item) => (
                                <Typography
                                    sx={{
                                        textAlign: 'left',
                                        color: 'white',
                                        fontSize: {
                                            xs: '1.2rem',
                                            sm: '1.5rem',
                                            md: '1.2rem',
                                            lg: '1.2vw',
                                        },
                                        fontFamily: 'Montserrat',
                                        fontWeight: 400,
                                        lineHeight: 1.2, // Reduce line spacing
                                        mb: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center', // Align icon and text
                                            gap: '8px', // Add spacing between icon and text
                                        }}
                                    >
                                        {hover ? (
                                            <CheckCircleOutline
                                                sx={{
                                                    color: '#F8AD13', // Default green color
                                                    fontSize: {
                                                        xs: "1.2rem",
                                                        md: '1.5rem'
                                                    }, 
                                                    mr: 1,
                                                }}
                                            />
                                        ) : (
                                            <Circle
                                                sx={{
                                                    color: '#ffffff', // Default green color
                                                    fontSize: {
                                                        xs: "1.2rem",
                                                        md: '1.5rem'
                                                    }, 
                                                    mr: 1,
                                                }}
                                            />
                                        )
                                        }
                                        {item}
                                    </Box>
                                </Typography>
                            ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default EcommercePlanCard;
