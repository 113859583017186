import { useRef, useState } from "react";
import { FiLock } from "react-icons/fi";
import { motion } from "framer-motion";
import {
  Button,
  Typography,
} from "@mui/material";

const EncryptedButton = () => {
  return (
    <EncryptButton />
  );
};

const TARGET_TEXT = "Learn More";
const CYCLES_PER_LETTER = 2;
const SHUFFLE_TIME = 50;

const CHARS = "!@#$%^&*():{};|,.<>/?";

const EncryptButton = () => {
  const intervalRef = useRef(null);

  const [text, setText] = useState(TARGET_TEXT);

  const scramble = () => {
    let pos = 0;

    intervalRef.current = setInterval(() => {
      const scrambled = TARGET_TEXT.split("")
        .map((char, index) => {
          if (pos / CYCLES_PER_LETTER > index) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setText(scrambled);
      pos++;

      if (pos >= TARGET_TEXT.length * CYCLES_PER_LETTER) {
        stopScramble();
      }
    }, SHUFFLE_TIME);
  };

  const stopScramble = () => {
    clearInterval(intervalRef.current || undefined);

    setText(TARGET_TEXT);
  };

  return (
    <motion.div
      whileHover={{
        scale: 1.1,
      }}
      whileTap={{
        scale: 0.975,
      }}
    >
      <Button
        variant="outlined"
        sx={{
          borderRadius: '999px', // Make the button oval
          background: 'transparent', // Set the background to transparent
          color: 'white', // Set the text color to white
          border: '3px solid white', // Set border to transparent initially
          '&:hover': {
            backgroundColor: '#F8AD13', // Change background color on hover
            border: '3px solid #F8AD13', // Change border color on hover
            color: 'black',
          },
          width: { xs: '200px', md: '250px', lg: '250px', xl: '250px' },
          height: { xs: '50px', md: '60px', lg: '60px', xl: '60px' },
          mt: { xs: 3, md: 3 },
          //cursor: 'pointer',
          //zIndex: 10000, 
          mx: 'auto',
          display: 'block',
        }}
        onClick={() => {
          // Smooth scroll to the target element
          document.querySelector("#about-section").scrollIntoView({
            behavior: "smooth"
          });
        }}
        onMouseEnter={scramble}
        onMouseLeave={stopScramble}
      >
        <div className="relative z-10 flex items-center gap-2">
          {/*FiLock />*/}
          <Typography
            sx={{
              fontWeight: 300,
              letterSpacing: '.1rem',
              fontSize: { xs: '1rem', md: '1.4rem', lg: '1.4rem', xl: '1.4rem' } //resize based on screensize
            }}
          >
            {text}
          </Typography>
        </div>
        <motion.span
          initial={{
            y: "100%",
          }}
          animate={{
            y: "-100%",
          }}
          transition={{
            repeat: Infinity,
            repeatType: "mirror",
            duration: 1,
            ease: "linear",
          }}
          className="absolute inset-0 scale-125 bg-gradient-to-t from-gray-800/10 via-gray-900/80 to-gray-800/10 opacity-100 z-[-1]"
        />
      </Button>
    </motion.div>
  );
};

export default EncryptedButton;