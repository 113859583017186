import React from 'react';
import { motion } from 'framer-motion';

import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  MenuList,
  Popover,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  Drawer,
  Hidden
} from '@mui/material';
import Grid from '@mui/material/Grid2';

//import FloatingPhoneSection from './FloatingPhone';

//Sections
import MyContactSection from './MyContact';
import LogoSection from './Logo';
import ContactFormSection from './ContactForm';
import TerminalContact from './TerminalContactForm';

const ContactSection = () => {
  return (
    <Grid container
      sx={{
        px: {xs: 2, md: 5, lg: 10},
        py: 3,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#0f0f0f',
        alignItems: 'center',
        //height: { xs: '100%', md: '63vw', lg: '60vw', xl: '100%' },
        //height: {xs: '100%', md: '50vw', lg: '30vw', xl: '20vw'},
        //minHeight: {xs: '100%', md: '20vw', lg: '20vw'}
        height: '100%'
      }}
      spacing={{ xs: 2, md: 2 }}
      id="contact-section"
    >

      <Grid container spacing={2} size={{ xs: 12, md: 6 }}>
        <Grid item size={12}>
          <MyContactSection />
        </Grid>

        <Grid item size={12}>
          <LogoSection />
        </Grid>
      </Grid>

      <Grid item
        size={{ xs: 12, md: 6 }}
        /*sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
          height: '100%',
          flexGrow: 1,
        }} */
        >
        <TerminalContact />
      </Grid>
    </Grid>
  );
};

export default ContactSection;
