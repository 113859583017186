import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { motion, AnimatePresence, useInView } from "framer-motion";

import BasicPlanCard from './Cards/basicPlanCard';
import EcommercePlanCard from './Cards/ecomPlanCard';
import WholeSalePlanCard from './Cards/wholePlanCard';
import BubbleTextSection from './BubbleText/BubbleText';

const PricingPlanSection = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <Box
            sx={{
                backgroundColor: '#0f0f0f',
                //pt: 2,
                pb: { xs: 5, md: 10 },
                px: { xs: 2, sm: 5, md: 5, xl: 10 },
            }}
            id="pricing-section"
        >
            <Grid
                container
                rowSpacing={{ xs: 5, md: 5, lg: 7 }}
                columnSpacing={{ xs: 5, xl: 7 }}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item size={{ xs: 12 }}>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 1, delay: 0.2 }}
                    >
                        <BubbleTextSection />
                        <Box
                            sx={{
                                width: { xs: '90%', md: '90%', xl: '80%' }, // Restrict the width of the text
                                margin: '0 auto', // Center the box horizontally
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    color: 'white',
                                    fontSize: {
                                        xs: '1rem',
                                        sm: '1.2rem',
                                        md: '1.5rem',
                                        lg: '1.5vw',
                                        xl: '1.5vw',
                                    },
                                    fontFamily: 'Montserrat',
                                    fontWeight: 400,
                                    mt: 4, // Add margin on top
                                }}
                            >
                                Most plans include website hosting, unlimited edits, secure SSL/HTTPS, optimized SEO, and 24/7 customer support.
                                Pricing evaluated on a case-by-case basis.
                            </Typography>
                        </Box>
                    </motion.div>
                </Grid>


                <Grid item size={{ xs: 12, md: 4 }} sx={{ px: { xs: 2, sm: 5, md: 0 } }}>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 1, delay: 0.6 }}
                    >
                        <Box
                            sx={{
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                },
                            }}
                        >
                            <BasicPlanCard />
                        </Box>
                    </motion.div>
                </Grid>


                <Grid item size={{ xs: 12, md: 4 }} sx={{ px: { xs: 2, sm: 5, md: 0 } }}>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 1, delay: 1 }}
                    >
                        <Box
                            sx={{
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                },
                            }}
                        >
                            <EcommercePlanCard />
                        </Box>
                    </motion.div>
                </Grid>

                <Grid item size={{ xs: 12, md: 4 }} sx={{ px: { xs: 2, sm: 5, md: 0 } }}>
                    <motion.div
                        ref={ref}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isInView ? { opacity: 1, y: 0 } : {}}
                        transition={{ duration: 1, delay: 1.3 }}
                    >
                        <Box
                            sx={{
                                transition: 'transform 0.3s ease-in-out',
                                '&:hover': {
                                    transform: 'scale(1.1)',
                                },
                            }}
                        >
                            <WholeSalePlanCard />
                        </Box>
                    </motion.div>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PricingPlanSection;
