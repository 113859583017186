import { Suspense, useState, useRef, useMemo, useEffect } from "react";

import { motion, useScroll, useTransform } from "framer-motion";
import Lenis from "lenis";
import { Box } from "@mui/material";

export default function CloudMntSection() {
  const ref = useRef(null);
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const { height } = dimension;
  //console.log("height:", height)
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  useEffect(() => {
    const lenis = new Lenis();

    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    const resize = () => {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", resize);
    requestAnimationFrame(raf);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        height: "100vh",
        background: "#00000e",
        position: "relative",
        zIndex: -1,
      }}
    >
       <motion.div
        style={{ y: backgroundY, height: "100vh" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }} // Fade in once video is loaded
        transition={{ duration: 1 }}
      >
        <video
          id="background-video"
          autoPlay
          loop
          muted // Add this to prevent audio playback
          playsInline
          preload="auto"
          fetchpriority="high"
          src="https://mammoth-photos.s3.us-east-1.amazonaws.com/MountainVid.mp4"
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensure the video covers the entire area
            zIndex: -5,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: 'common.black',
            opacity: 0.5, //Background Opacity
            zIndex: -1,
          }}
        />
      </motion.div>
    </div>
  );
}
